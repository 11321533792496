<template>
  <svg
    id="86d418c6-0895-4527-a086-d04df871ad3c"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.37,0H29.63A7.38,7.38,0,0,1,37,7.38V29.63A7.37,7.37,0,0,1,29.63,37H7.38A7.37,7.37,0,0,1,0,29.63V7.37A7.37,7.37,0,0,1,7.37,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M26.06,11.82,19.28,7.3a1.36,1.36,0,0,0-1.54,0L11,11.82A1.4,1.4,0,0,0,10.34,
        13V28.16a1.39,1.39,0,0,0,1.39,1.39h1.34a.21.21,0,0,0,.21-.21V21.09a.38.38,0,0,
        1,.38-.38h4.16a.39.39,0,0,1,.39.38v8.29a.17.17,0,0,0,.17.17h6.9a1.39,
        1.39,0,0,0,1.39-1.39V13A1.4,1.4,0,0,0,26.06,11.82ZM23.93,24.88a.38.38,
        0,0,1-.38.38H20.62a.4.4,0,0,1-.39-.39V21.18a.4.4,0,0,1,.39-.39h2.92a.39.39,
        0,0,1,.38.39Zm0-7.55a.38.38,0,0,1-.38.38H13.65a.38.38,0,0,
        1-.38-.38V14.9a.4.4,0,0,1,.39-.39h9.88a.38.38,0,0,1,.38.38Z"
        transform="translate(-0.01 -0.02)"
        fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>